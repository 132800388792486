<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Welcome Settings</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" class="client-portal-setting-form" ref="welcome-settings-form">
                <div class="modal_body">
                    <div class="tabs_content">
                        <div class="section_content w-100" v-show="type == 1">
                            <div class="toggle_content">
                                <div class="color_container">
                                    <div class="setting_wpr">
                                        <div class="capsule_elm">
                                            <h5>Show</h5>
                                            <label for="welcome_video" class="switch_option capsule_btn">
                                                <input type="checkbox" id="welcome_video" :true-value="1" :false-value="0" v-model="form.is_enabled" @change="handleAutoSave" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="mt-3" v-if="form.is_enabled">
                                                <div class="setting_wpr mb-3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">
                                                                Widget Title
                                                                <a class="reset-default ml-auto" @click="resetDefault('title')">Reset to default</a>
                                                                <div class="dropdown mr-1 ml-3" @click="personalizeOption = !personalizeOption" v-click-outside="hidePersonalizeOption">
                                                                    <i class="fas fa-user-alt"></i>
                                                                    <div class="drp_wrapper" v-if="personalizeOption" :class="{ active : personalizeOption }">
                                                                        <ul>
                                                                            <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">{{ personalize }}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" id="welcome-title-field" name="title" v-model="form.title" placeholder="ex: Welcome, &#123;&#123;first_name&#125;&#125;!" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Coach Greeting Text <a class="reset-default" @click="resetDefault('greeting')">Reset to default</a></label>
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="coach_text" v-model="form.coach_text" placeholder="ex: Meet your coach" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Coach Greeting Sub Text <a class="reset-default" @click="resetDefault('subGreeting')">Reset to default</a></label>
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="coach_sub_text" v-model="form.coach_sub_text" placeholder="ex: &#123;&#123;owner_full_name&#125;&#125; - &#123;&#123;company_name&#125;&#125;" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="edit_section mb-5">
                                                    <h3 class="sub_heading2 m-0">
                                                        Show Coaches
                                                        <label for="has_coaches" class="switch_option capsule_btn ml-auto">
                                                            <input type="checkbox" id="has_coaches" :true-value="1" :false-value="0" v-model="form.has_coaches" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <ul class="coach_stack">
                                                            <template v-for="(coach, c) in coaches" :key="c" >
                                                                <li class="coach_box" v-if="coach.enabled" :style="`z-index: ${coaches.length - c}`">
                                                                    <img v-if="coach.profile_pic" :src="coach.profile_pic" alt="">
                                                                    <div v-else class="coach_name">
                                                                        <template v-if="coach.fname">{{ coach.fname[0] }}</template>
                                                                        <template v-if="coach.lname">{{ coach.lname[0] }}</template>
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                    </h3>
                                                    <draggable v-model="coaches" tag="ul" class="coach_list" item-key="id" :animation="200" handle=".handle-drag" v-if="form.has_coaches" @change="handleSortCoaches">
                                                        <template #item="{ element: coach, index: c }"> 
                                                            <li>
                                                                <div class="coach">
                                                                    <i class="fas fa-arrows-alt mr-2 handle-drag"></i>
                                                                    <div class="coach_box">
                                                                        <img v-if="coach.profile_pic" :src="coach.profile_pic" alt="">
                                                                        <div v-else class="coach_name">
                                                                            <template v-if="coach.fname">{{ coach.fname[0] }}</template>
                                                                            <template v-if="coach.lname">{{ coach.lname[0] }}</template>
                                                                        </div>
                                                                        <span class="edit" @click="handleCoachEdit(coach)"><i class="fas fa-pen"></i></span>
                                                                    </div>
                                                                    <h5>{{coach.fname}} {{ coach.lname }}<label class="tag" v-if="coach.owner">Owner</label><span>{{coach.credential}}</span></h5>
                                                                    <label :for="`assigned-${c}`" class="switch_option capsule_btn ml-auto">
                                                                        <input type="checkbox" :id="`assigned-${c}`" :true-value="1" :false-value="0" v-model="coach.enabled" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </template>
                                                    </draggable>
                                                </div>
                                                <div class="edit_section mb-4">
                                                    <h3 class="sub_heading2 m-0">
                                                        Show Video
                                                        <label for="showVideo" class="switch_option capsule_btn ml-auto">
                                                            <input type="checkbox" id="showVideo" :true-value="1" :false-value="0" v-model="form.has_welcome_video" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </h3>
                                                    <template v-if="form.has_welcome_video">
                                                        <div class="capsule_elm mt-2">
                                                            <h5>Video Link</h5>
                                                            <label for="video_url" class="switch_option capsule_btn">
                                                                <input type="radio" id="video_url" :value="1" v-model="form.welcome_video.type" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="form_grp pt-0" v-if="form.welcome_video.type == 1">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="off" type="text" name="welcome_video_link" v-model="form.welcome_video.link" placeholder="ex:https://www.youtube.com/watch?v=eaRQF-7hhmo" rules="url" label="video link" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="capsule_elm">
                                                            <h5>Embeded Video</h5>
                                                            <label for="embed_video" class="switch_option capsule_btn">
                                                                <input type="radio" id="embed_video" :value="2" v-model="form.welcome_video.type" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="form_grp pt-0" v-if="form.welcome_video.type == 2">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="off" type="textarea" name="welcome_video_embed_code" v-model="form.welcome_video_embed_code" label="embed code">
                                                                        <textarea name="welcome_video_embed_code" v-model="form.welcome_video.embed" placeholder="embeded code here.."></textarea>
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="capsule_elm">
                                                            <h5>Image</h5>
                                                            <label for="add_image" class="switch_option capsule_btn">
                                                                <input type="radio" id="add_image" name="instruction_type" :value="3" v-model="form.welcome_video.type" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="upload_image" v-if="form.welcome_video.type == 3">
                                                            <image-library v-model="form.welcome_video.image" image-type="landscape" upload-text="Image" />
                                                        </div>
                                                    </template>
                                                </div>
                                                <div class="form_grp mb-4">
                                                    <div class="group_item">
                                                        <label class="input_label">Welcome Message</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.welcome_text }">
                                                            <Field autocomplete="off" name="welcome_message" v-model="form.welcome_text"  :rules="`${form.welcome_text ? 'required' : ''}`" label="message">
                                                                <redactor v-model="form.welcome_text" name="welcome_message" :default-value="welcomeDefaultText" ref="step-editor" :email-editor="false" :reset-watcher="welcomeTextResetWatcher" />
                                                            </Field>
                                                        </div>
                                                        <ErrorMessage name="welcome_message" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="edit_section">
                                                    <h3 class="sub_heading2 m-0">
                                                        Show Onboarding<small class="ml-2">(Coming Soon)</small>
                                                        <label for="has_onboarding" class="switch_option capsule_btn ml-auto">
                                                            <input type="checkbox" id="has_onboarding" :true-value="1" :false-value="0" v-model="form.has_onboarding" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </h3>
                                                    <div class="form_grp" v-if="form.has_onboarding">
                                                        <div class="group_item">
                                                            <label class="input_label">CTA Button Text <a class="reset-default" @click="resetDefault('onboarding')">Reset to default</a></label>
                                                            <div class="field_wpr">
                                                                <Field autocomplete="off" type="text" name="onboarding_cta_text" v-model="form.onboarding_cta_text" placeholder="ex: Start Onboarding!" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="details_wpr" v-if="editCoach">
                            <div class="cardItem_details">
                                <div class="header">
                                    <button class="close_btn" @click="editCoach = false;"><i class="fas fa-chevron-down"></i></button>
                                    <h2>Edit Coach</h2>
                                </div>
                                <div class="setting_wpr pb-4">
                                    <div class="upload_image mb-3">
                                        <div class="sub_heading2">Coach Image</div>
                                        <image-library v-model="coachForm.profile_pic" image-type="coach-logo" upload-text="New Image" />
                                    </div>
                                </div>
                                <div class="action_wpr fixed_footer">
                                    <button type="button" class="btn cancel_btn" @click="editCoach = false;">Cancel</button>
                                    <button class="btn save_btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="updateLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="updateLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="updateLoader"></i> {{ updateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'
import Draggable from 'vuedraggable'
import constants from '@/utils/Constant'

export default {
    name: 'welcome-setting',

    data () {
        return  {
            session_label: 1,
            personalizeOption: false,
            defaultPersonalize: [],
            editCoach: false,
            selectedCoach: 0,
            form: {
                is_enabled: 0,
                title: '',
                welcome_video: {},
                has_onboarding: 0,
                onboarding_cta_text: '',
                coach_text: '',
                coach_sub_text: '',
                welcome_text: '<p>Are you looking to improve your fitness and overall health? Regular exercise is key! Whether you\'re aiming to lose weight, build muscle, or simply improve your endurance, incorporating physical activity into your daily routine can have numerous benefits. Exercise not only helps you look and feel better, but it also boosts your mood and reduces...</p>',
                has_coaches: 1,
                coach_image: '',
            },
            coaches:[],
            coachForm: {
                id: 0,
                profile_pic: '',
            },
            welcomeDefaultText: '<p>Are you looking to improve your fitness and overall health? Regular exercise is key! Whether you\'re aiming to lose weight, build muscle, or simply improve your endurance, incorporating physical activity into your daily routine can have numerous benefits. Exercise not only helps you look and feel better, but it also boosts your mood and reduces...</p>',
            welcomeTextResetWatcher: 0,
        };
    },

    props: {
        modelValue: Boolean,
        type: Number,
    },

    emits: ['update:modelValue'],

    watch: {
        modelValue (val) {
            if (val) {
                const vm = this;
                vm.resetForm();

                document.body.classList.add('modal-open');

                setTimeout(function () {
                    vm.loaded = true;
                }, 2000);
            } else {
                document.body.classList.remove('modal-open');
            }
        },
    },

    components: {
        Field,
        Form,
        ErrorMessage,
        Draggable
    },

    computed: mapState({
        clientPortal: state => state.clientPortalModule.clientPortal,
        updateLoader: state => state.clientPortalModule.updateLoader,
    }),

    mounted () {
            const vm = this;

            vm.defaultPersonalize = constants.personalize.concat(vm.personalize);
        },

    methods: {
        ...mapActions({
            updateWelcomeSetting: 'clientPortalModule/updateWelcomeSetting',
        }),

        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },

        resetForm () {
            const vm = this;

            vm.form     = JSON.parse(JSON.stringify(vm.clientPortal.welcome_settings));
            vm.coaches  = vm.form.active_coaches;

            vm.welcomeDefaultText = vm.form.welcome_text;

            setTimeout(() => {
                vm.welcomeTextResetWatcher++;
            }, 1000)
        },

        handleSubmit (form, { setFieldError }) {
            const vm = this;
            const params = JSON.parse(JSON.stringify(vm.form));

            params.setFieldError = setFieldError;

            vm.updateWelcomeSetting(params).then((result) => {
                if (result) {
                    vm.closeModal();
                }
            });
        },

        handleAutoSave () {
            const vm = this;
            const params = JSON.parse(JSON.stringify(vm.form));

            const form = vm.$refs['welcome-settings-form'];

            params.setFieldError = form.setFieldError;

            vm.updateWelcomeSetting(params);
        },

        insertPersonalize (personalize) {
            const vm = this;
            const element     = document.getElementById('welcome-title-field');
            const caretPos    = element.selectionStart;
            const textAreaTxt = element.value;
            const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

            vm.form.title = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
        },

        resetDefault (field) {
            const vm = this;

            if (field == 'title') {
                vm.form.title = 'Welcome, {{ first_name }}!';
            }

            if (field == 'greeting') {
                vm.form.coach_text = 'Meet Your Coach!';
            }

            if (field == 'subGreeting') {
                vm.form.coach_sub_text = '{{ owner_full_name }} - {{ company_name }}';
            }

            if (field == 'onboarding') {
                vm.form.onboarding_cta_text = 'Start Onboarding!';
            }
        },

        hidePersonalizeOption () {
            const vm = this;

            vm.personalizeOption = false;
        },

        handleCoachEdit (n) {
            const vm = this;

            vm.selectedCoach = n;
            vm.editCoach = true;
        },

        handleSortCoaches () {
            const vm = this;

            vm.updateWelcomeSetting({ id: vm.form.id, active_coaches: vm.coaches });
        },
    },
};
</script>

<style scoped>
    .modal_body .tab_content{
        height: 100%;
    }
    .modal_body .tab_content :deep(.setting_wpr){
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .modal_body .tab_content :deep(.setting_wpr .section_wpr:last-of-type){
        margin-top: auto;
        padding-bottom: 50px;
    }

    .modal_container {
        height: 100% !important;
    }

    .client-portal-setting-form {
        height: calc(100% - 153px);
    }
    .coach_stack{
        display: flex;
        flex-direction: row;
        margin-left: 15px;
        padding: 0;
    }
    .coach_stack li:not(:first-child){
        margin-left: -10px;
    }
    .coach_stack .coach_box {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        padding: 2px;
        flex-shrink: 0;
        position: relative;
        z-index: 1;
    }
    .coach_stack .coach_box:before{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #fff;
        z-index: 0;
    }
    .coach_stack .coach_box .coach_name{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        background: var(--var-portal-tab-background-color);
        color: #fff;
        position: relative;
        z-index: 1;
    }
    .coach_stack .coach_box img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    .coach_list{
        padding-top: 10px;
    }
    .coach_list li{
        padding: 15px 0;
    }
    .coach_list li:not(:last-child){
        border-bottom: 1px solid #e9e9e9;
    }
    .coach_list .coach {
        display: flex;
        align-items: center;
    }
    .coach_list .coach .handle-drag{
        font-size: 14px;
        color: #9f9e9e;
        cursor: move;
    }
    .coach_list .coach .coach_box{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: var(--var-portal-tab-bg-color50);
        margin-right: 15px;
        padding: 3px;
        flex-shrink: 0;
        position: relative;
    }
    .coach_list .coach .coach_box .coach_name{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        background: var(--var-portal-tab-background-color);
        color: #fff;
    }
    .coach_list .coach .coach_box img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
    .coach_list .edit {
        position: absolute;
        left: -3px;
        bottom: -3px;
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        font-size: 10px;
        line-height: 20px;
        display: block;
        text-align: center;
        border: 1px solid #d9d9d9;
        background: #fff;
        color: #5a5a5a;
        cursor: pointer;
        border-radius: 50%;
        margin: 0;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .coach_list li:hover .edit {
        opacity: 1;
    }
    .coach_list .coach h5{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
    }
    .coach_list .coach h5 span{
        display: block;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #5a5a5a;
        padding-top: 5px;
    }
    .coach_list .coach h5 .tag{
        padding: 2px 8px;
        border-radius: 10px;
        background: #e9e9e9;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        margin-left: 10px;
    }
</style>
